export const SIGN_IN: string = 'Sign in'
export const LOGIN: string = 'Login'
export const SIGN_UP: string = 'Sign up'
export const FORGOT_PASSWORD: string = 'Forgot Password?'
export const NO_ACCOUNT: string = 'Don’t have an account?'
export const HAVE_ACCOUNT: string = 'Have an account?'
export const SEND_CODE: string = 'Send Code'
export const NEXT: string = 'Next'
export const FINISH: string = 'Finish'
export const RESET: string = 'Reset'
export const SUBMIT: string = 'Submit'
export const SET: string = 'Set'
export const SET_NEW_PASSWORD: string = 'Set new password'
