export const becomePartner = 'I want to be a partner'
export const partnerQuestionDescription = [
  {
    id: 1,
    title: 'What is our Partner program about?',
    description:
      'Our Partner program is the way to collaborate by promoting TestNotion on your platform and getting revenue from each Admin. All you have to do is join the program and share referral link anywhere Your audience is concentrated. Each Admin that registers and uses TestNotion to schedule an assessment will provide you revenue.',
  },
  {
    id: 2,
    title: 'Who needs to be part of our Partners Program?',
    description:
      'There are no limits to join our Partners program. You can start promoting TestNotion anytime you want. The best result usually achieved when you share the links on your own platform, blog, social profile with an appropriate audience.',
  },
]

export const partnerThreeStepTitle: string = 'Make money in 3 easy steps'
export const partnerThreeSteps = [
  {
    id: 1,
    title: 'Sign Up',
    description: `Create a TestNotion partner account. It's easy and free.`,
  },
  {
    id: 2,
    title: 'Start Referring',
    description: 'Share your unique partner link.',
  },
  {
    id: 3,
    title: 'Get Paid',
    description: 'Earn commissions when admins sign up.',
  },
]

export const partnerFourStepTitle: string = 'This is for You!'
export const partnerFourStepDescription: string =
  'If you’re here, it means two things. You came here by mistake/curiosity. Or you’re....'
export const partnerFourSteps = [
  {
    id: 1,
    title: 1,
    description: 'Someone who wants to create a new source of revenue.',
  },
  {
    id: 2,
    title: 2,
    description: 'Eager to spread the word and help others use TestNotion.',
  },
  {
    id: 3,
    title: 3,
    description:
      'Believe TestNotion is the new tool to carry out online tests fast, safely, and conveniently.',
  },
  {
    id: 4,
    title: 4,
    description: 'Passionate about online testing.',
  },
]
