import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  useToast,
  Grid,
  FormControl,
  GridItem,
} from '@chakra-ui/react'
import { TextInput } from '../Input'
import CustomButton from '../Button'
import { EMAIL_REGEX, FREE_TEXT_REGEX } from '@/lib/constants/regexConstants'
import useForm from '@/hooks/useForm'
import { axiosInstance, handleErrorResponse } from '@/lib/helpers'
import LEColorConstants from '@/lib/constants/colorConstants'
import { becomePartner } from '@/lib/constants/partner'

interface IHelpModal {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  partnerAllow?: boolean
}

const FORMDATA = [
  {
    id: 1,
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
    pattern: FREE_TEXT_REGEX,
    errorMessage: 'Please Enter a valid name',
    span: 1,
  },
  {
    id: 2,
    label: 'Email',
    name: 'email',
    type: 'email',
    required: true,
    pattern: EMAIL_REGEX,
    errorMessage: 'Please Enter a valid email address',
    span: 1,
  },
  {
    id: 3,
    label: 'Message',
    name: 'message',
    type: 'textarea',
    required: true,
    pattern: FREE_TEXT_REGEX,
    errorMessage: 'Please Enter a valid message',
    span: 2,
  },
]

export default function HelpModal({
  isOpen,
  onClose,
  onClick,
  partnerAllow = false,
}: IHelpModal) {
  const toast = useToast()

  const modalSize: any = {
    md: '900px',
    base: '800px',
    lg: '1400px',
    sm: '700px',
  }

  const { handleChange, inputTypes, handleSubmit, errors, loading } = useForm({
    inputs: FORMDATA,
    cb: async inputs => {
      const data = {
        name: inputs.name,
        email: inputs.email,
        message: inputs.message,
      }
      try {
        const res = await axiosInstance.post('/config/contact_us', data)
        toast({
          title: partnerAllow
            ? 'Your application to become a partner was submitted successfully, and we will contact you as soon as we can. Thanks'
            : `${res.data.message}`,
          description: '',
          status: `success`,
          variant: 'top-accent',
          duration: 4000,
          isClosable: true,
        })
        inputTypes.name = inputTypes.email = inputTypes.message = ''
        setTimeout(() => {
          onClose()
        }, 2500)
      } catch (err) {
        handleErrorResponse(err)
      }
    },
  })

  return (
    <>
      <Modal
        isOpen={isOpen}
        size={['sm', 'md', 'base', 'lg']}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent maxW={modalSize}>
          <ModalBody padding="3rem">
            <Text fontSize="1rem" mb="1.7rem">
              Please fill the information below, we will contact you as soon as
              possible.
            </Text>

            <form action="post">
              <Grid
                columnGap={['0.5rem', '2rem']}
                templateAreas={`"name email"
                                "message message"
                                    `}
                templateColumns="1fr, 1fr"
                gridTemplateColumns={'1fr 1fr'}
                gridTemplateRows="1fr, 1fr"
                rowGap="2rem"
              >
                {FORMDATA.map((data, i) => (
                  <>
                    <GridItem area={`${data.name}`} w="100%" key={`help${i}`}>
                      <FormControl isInvalid={errors[data.name]}>
                        {data.type !== 'textarea' ? (
                          <TextInput
                            type="text"
                            name={data.name}
                            placeholder={data.label}
                            value={inputTypes[data.name]}
                            rows={10}
                            handleChange={handleChange}
                            error={errors[data.name] && data.errorMessage}
                            TextInputProps={{
                              _focusWithin: {
                                border:
                                  !errors[data.name] &&
                                  `2px solid ${LEColorConstants.primary}`,
                              },
                            }}
                          />
                        ) : (
                          <GridItem area={`${data.name}`} w="100%">
                            <TextInput
                              name={data.name}
                              type="textarea"
                              value={
                                partnerAllow
                                  ? (inputTypes['message'] = becomePartner)
                                  : inputTypes['message']
                              }
                              handleChange={handleChange}
                              placeholder={data.label}
                              error={errors[data.name] && data.errorMessage}
                              TextInputProps={{
                                isReadOnly: partnerAllow ? true : false,
                                width: 'full',
                                _focusWithin: {
                                  border:
                                    !errors[data.name] &&
                                    `2px solid ${LEColorConstants.primary}`,
                                },
                              }}
                            />
                          </GridItem>
                        )}
                      </FormControl>
                    </GridItem>
                  </>
                ))}
              </Grid>
            </form>

            <Box mt="2.8rem" textAlign="center">
              <CustomButton
                width="10.6rem"
                height="3.4rem"
                fontSize={'1rem'}
                fontWeight="medium"
                borderRadius={'5px'}
                onClick={handleSubmit}
                isLoading={loading}
              >
                Submit
              </CustomButton>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
